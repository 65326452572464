var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.RESELLER')} (*)`,"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"allowNone":true,"reseller":_vm.resellerInvoice.reseller.id,"filterable":true,"showAll":false,"disabled":!!_vm.resellerInvoice.id},on:{"resellerChanged":(resellerId) => {
              _vm.resellerInvoice.reseller.id = resellerId;
              _vm.resellerInvoice.recipient.id = null;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('RESELLER_INVOICES.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('RESELLER_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            minDate: 'today',
            locale: _vm.$flatPickrLocale(),
          }},on:{"on-change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.resellerInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "expiration_time", $$v)},expression:"resellerInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t(`RESELLER_INVOICES.SELECT_ORGANIZATION`)} (*)`}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.resellerInvoice.recipient.id,"filterReseller":_vm.resellerInvoice.reseller.id,"filterable":true,"showAll":false,"disabled":!!_vm.resellerInvoice.id},on:{"organizationChanged":(organizationId, organization) => {
              _vm.resellerInvoice.recipient.id = organizationId;
              if (organization) {
                _vm.resellerInvoice.billing_entity_type =
                  organization.billing_entity_type;
                _vm.resellerInvoice.billing_firstname =
                  organization.billing_firstname;
                _vm.resellerInvoice.billing_lastname =
                  organization.billing_lastname;
                _vm.resellerInvoice.billing_company_name =
                  organization.billing_company_name;
                _vm.resellerInvoice.billing_email = organization.billing_email;
                _vm.resellerInvoice.billing_country =
                  organization.billing_country;
                _vm.resellerInvoice.billing_state = organization.billing_state;
                _vm.resellerInvoice.billing_city = organization.billing_city;
                _vm.resellerInvoice.billing_zipcode =
                  organization.billing_zipcode;
                _vm.resellerInvoice.billing_address =
                  organization.billing_address;
              }
              _vm.onFormChanged();
            }}})],1)],1),_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))])]),_c('div',{staticClass:"form-wrapper full"},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.resellerInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "excerpt", $$v)},expression:"resellerInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_INFORMATIONS")))])]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":_vm.onFormChanged}},[_c('el-select',{attrs:{"name":"Type"},model:{value:(_vm.resellerInvoice.billing_entity_type),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_entity_type", $$v)},expression:"resellerInvoice.billing_entity_type"}},[_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_INDIVIDUAL,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_COMPANY,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_entity_type}})],1),(
        _vm.resellerInvoice.billing_entity_type == _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
      )?_c('div',{staticClass:"form-group-wrapper"},[_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.FIRSTNAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_firstname),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_firstname", $$v)},expression:"resellerInvoice.billing_firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_firstname}})],1),_c('div',{staticClass:"form-group-wrapper-item"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.LASTNAME')},model:{value:(_vm.resellerInvoice.billing_lastname),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_lastname", $$v)},expression:"resellerInvoice.billing_lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_lastname}})],1)]):_vm._e(),(
        _vm.resellerInvoice.billing_entity_type == _vm.BILLING_ENTITY_TYPE_COMPANY
      )?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COMPANY_NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_company_name),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_company_name", $$v)},expression:"resellerInvoice.billing_company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_company_name}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EMAIL')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_email),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_email", $$v)},expression:"resellerInvoice.billing_email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_email}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COUNTRY'),"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.resellerInvoice.billing_country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
              _vm.resellerInvoice.billing_country = country;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_country}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.STATE'),"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.resellerInvoice.billing_country,"state":_vm.resellerInvoice.billing_state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
              _vm.resellerInvoice.billing_state = state;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_state}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.CITY')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_city),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_city", $$v)},expression:"resellerInvoice.billing_city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_city}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ZIPCODE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_zipcode),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_zipcode", $$v)},expression:"resellerInvoice.billing_zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_zipcode}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ADDRESS')},on:{"change":_vm.onFormChanged},model:{value:(_vm.resellerInvoice.billing_address),callback:function ($$v) {_vm.$set(_vm.resellerInvoice, "billing_address", $$v)},expression:"resellerInvoice.billing_address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_address}})],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onCloseResellerInvoiceModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm kw-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.resellerInvoice.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }